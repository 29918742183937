<template>
  <div class="drvers">
    <LogsList :type="`managers`">
      <PageTitle title="TRANSACTIONS.TITLE" subtitle="TRANSACTIONS.SUBTITLE"></PageTitle>
    </LogsList>
  </div>
</template>
<script>
import LogsList from "@/components/Modules/Transactions/TransactionsList/index.vue"
import PageTitle from "@/components/Shared/PageTitle/index.vue"
export default {
  components: {
    LogsList,
    PageTitle
  }
}
</script>