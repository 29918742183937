<template>
  <div class="dashboard">
    <DefaultLayout>
      <slot></slot>
      <FilterBox :roles="roles" />
      <b-table
        class="custom-table"
        :items="items"
        :busy="loading"
        :fields="fields"
        responsive
        :sticky-header="true"
        show-empty
        :tbody-transition-props="transProps"
      >
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle" />
          </div>
        </template>

        <template #empty>
          <div class="text-center p-4">
            {{ $t("GLOBAL.NO_DATA") }}
          </div>
        </template>
        <template #cell(id)="data">
          <div>
            {{ data.index + 1 }}
          </div>
        </template>
        <template #cell(name)="data">
          <div>
            <div class="table-profile" v-if="data.item.user != null">
              <img :src="data.item.user.avatar != null ? data.item.user.avatar.url : default_avatar" />
              <p>{{ data.item.user.name }}</p>
            </div>
          </div>
        </template>
        <template #cell(phone)="data">
          <div>
            {{ data.item.order.phone_number }}
          </div>
        </template>
        <template #cell(payment_method)="data">
          <div>
            {{ data.item.order.payment_method_id == 1?'Online Payment By Knet':'Cashe on delivery' }}
          </div>
        </template>
        <template #cell(driver)="data">
          <div>
            {{ data.item.order.driver_id != null?data.item.order.driver.name:'No driver accept' }}
          </div>
        </template>

      </b-table>
      <!-- <CustomPagination :current_page="current_page" :requestStatus="requestStatus" :links="links" @paginatePrev="paginatePrev" @paginateNext="paginateNext" @getPagination="getPagination"/> -->
      <Pagination
        :current-page="current_page"
        :total-items="total_items"
        :per-page="per_page"
        class="pagination"
      />
      <EditUserDetails />
      <AddUser @saveChanges="handleAddMember" />
      <ModalDetails :user="user" />
    </DefaultLayout>
  </div>
</template>

<script>
import FilterBox from "@/components/Shared/FilterBox/index.vue"
import EditUserDetails from "@/components/Shared/EditUserDetails/index.vue"
import AddUser from "@/components/Shared/AddUser/index.vue"
// import CustomPagination from "@/components/Shared/CustomPagination/index.vue"
import { getUsersRequest, getUserByIdRequest, deleteUser, createUser } from "@/api/users.js"
import { getTransactionsData } from "@/api/reports.js"
import { mapActions } from "vuex"
import ModalDetails from "../../../Shared/EditUserDetails/index.vue"

export default {
  props: {
    type: {
      type: Boolean
    }
  },
  components: {
    FilterBox,
    EditUserDetails,
    AddUser,
    ModalDetails,
    // CustomPagination
  },
  data() {
    return {
      toggleVal: false,
      logs: [],
      user: {},
      transProps: {
        name: "flip-list"
      },
      loading: false,
      default_avatar: require("@/assets/images/user.png"),
      current_page: 1,
      total_items: 0,
      per_page: 0,
      roles: [
        {
          id: 1,
          name: "role 1"
        },
        {
          id: 2,
          name: "role 2"
        },
        {
          id: 3,
          name: "role 3"
        }
      ],
      fields: [
        { key: "id", label: "#", sortable: false },
        {
          key: "phone",
          label: this.$t("GLOBAL.PHONE"),
          sortable: false
        },
        {
          key: "total",
          label: this.$t("GLOBAL.TOTAL"),
          sortable: false
        },
        {
          key: "payment_method",
          label: this.$t("GLOBAL.PAYMENT METHOD"),
          sortable: false
        },
        {
          key: "driver",
          label: this.$t("GLOBAL.DRIVER"),
          sortable: false
        },
        {
          key: "created_at",
          label: this.$t("GLOBAL.created_at"),
          sortable: false
        },
      ],
      items: []
    }
  },
  mounted() {
    this.getTransactions()
  },
  methods: {
    ...mapActions(["ShowToast"]),
    handleEdit(obj) {
      this.ApiService(getUserByIdRequest(obj.id))
        .then((res) => {
          this.user = res.data.data
          this.$bvModal.show("edit-user-details")
        })
        .catch((err) => {
          this.ShowToast({
            title: this.$t("Error"),
            type: "danger",
            message: this.$t(err)
          })
        })
    },
    handleDelete(obj) {
      this.ApiService(deleteUser(obj.id)).then((res) => {
        if (res.data.status == "success") {
          this.items.splice(this.items.indexOf(obj), 1)
          this.ShowToast({
            title: this.$t("Delete User"),
            type: "danger",
            message: this.$t("Deleted Success")
          })
          return res
        }
      })
    },
    handleAddMember(data) {
      console.log(data)
      this.ApiService(createUser(data))
      .then(res => {
        if(res.data.status == "success"){
          this.$bvModal.hide("add-user")
          location.reload()
        }
      })
    },
    handleAddTransport() {
      this.$bvModal.show("add-transport")
    },
    getTransactions() {
      this.loading = true
      this.ApiService(getTransactionsData(1))
        .then((res) => {
          if (res.data.status != "success") {
            console.log(res)
            this.ShowToast({
              title: this.$t("GLOBAL.GLOBAL_FAILED"),
              type: "danger",
              message: res.data.message || this.$t("GLOBAL.GLOBAL_FAILED")
            })
            return res
          }
          this.items = res.data.data.data
          this.current_page = res.data.data.current_page
          this.total_items = res.data.data.total
          this.per_page = res.data.data.per_page
        })
        .finally(() => {
          this.loading = false
        })
    },
  }
}
</script>

<style scoped lang="scss">
@import "./index.scss";
</style>
